/*------------------------------------*\
	# FONTS
\*------------------------------------*/

// Supports weights 100-900
// @import '~@fontsource-variable/roboto-slab';
/* roboto-slab-latin-wght-normal */
@font-face {
  font-family: 'Roboto Slab Variable';
  font-style: normal;
  font-display: block;
  font-weight: 100 900;
  src: url(./files/roboto-slab-latin-wght-normal.woff2) format('woff2-variations');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  font-optical-sizing: auto;
  font-variant-ligatures: common-ligatures;
}

// Supports weights 100-900
// @import '~@fontsource-variable/inter';
/* inter-latin-wght-normal */
@font-face {
  font-family: 'Inter Variable';
  font-style: normal;
  font-display: block;
  font-weight: 100 900;
  src: url(./files/inter-latin-wght-normal.woff2) format('woff2-variations');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  // font-optical-sizing: auto;
  // font-variation-settings: 'opsz' 16;
  font-variation-settings: 'opsz' 480;
  font-variant-ligatures: common-ligatures;
}

// Supports weights 100-900
// @import '~@fontsource-variable/inter-tight';

// Supports weights 200-700
// @import '~@fontsource-variable/oswald';
/* oswald-latin-wght-normal */
@font-face {
  font-family: 'Oswald Variable';
  font-style: normal;
  font-display: auto;
  font-weight: 200 700;
  src: url(./files/oswald-latin-wght-normal.woff2) format('woff2-variations');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
  font-optical-sizing: auto;
  font-variant-ligatures: common-ligatures;
}

/* roboto-slab-latin-wght-normal */
// @font-face {
//   font-family: 'Roboto Slab Variable';
//   font-style: normal;
//   font-display: auto;
//   font-weight: 100 900;
//   src: url(@fontsource-variable/roboto-slab/files/roboto-slab-latin-wght-normal.woff2) format('woff2-variations');
//   unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
// }

$font-family-roboto-slab: #{''+"'Roboto Slab Variable', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"} !default;
$font-family-inter: #{''+"'Inter Variable', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"} !default;
$font-family-oswald: #{''+"'Oswald Variable', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"} !default;
$font-family-system: #{''+"system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"} !default;

$font__sans-serif: $font-family-inter !default;
$font__serif: $font-family-roboto-slab !default;

$font__sans-serif: $font-family-inter !default;
$font__main: $font__sans-serif !default;
$font__code: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace !default;
$font__pre: $font__code !default;

.font-family-roboto-slab {
	font-family: $font-family-roboto-slab !important;
	font-optical-sizing: auto;
}

.font-family-inter {
  font-family: $font-family-inter !important;
  font-optical-sizing: auto;
  font-variation-settings: 'slnt' 0;
}

.font-family-oswald {
  font-family: $font-family-oswald !important;
  font-optical-sizing: auto;
}

