/*------------------------------------*\
	# COLOURS
\*------------------------------------*/

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
///
@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
///
@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

/* = MAP COLLECT (used for merging multiple maps)
https://gist.github.com/bigglesrocks/d75091700f8f2be5abfe
----------------------------------------------- */
@function map-collect($maps...) {
	$collection: ();

	@each $map in $maps {
		$collection: map-merge($collection, $map);
	}

	@return $collection;

}


/* = BOOTSTRAP COLOURS
----------------------------------------------- */
/* = COLORS
----------------------------------------------- */


/* = COMMON
..................................*/

$white:    #fff !default;
$gray-100: #f9fafa !default; // Custom
$gray-200: #f2f6fa !default; // Custom
$gray-300: #ebf1f8 !default; // Custom
$gray-400: #d5e0ef !default; // Custom
$gray-500: #F2F2F2 !default; // Custom
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #2C2A26 !default;

$black:    #000 !default;
$blue:    #cbe2e7 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #60935E !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $black !default;
$color-contrast-light:     $white !default;


// fusv-disable
$blue-100: tint-color($blue, 80%) !default;
$blue-200: tint-color($blue, 60%) !default;
$blue-300: tint-color($blue, 40%) !default;
$blue-400: tint-color($blue, 20%) !default;
$blue-500: $blue !default;
$blue-600: shade-color($blue, 20%) !default;
$blue-700: shade-color($blue, 40%) !default;
$blue-800: shade-color($blue, 60%) !default;
$blue-900: shade-color($blue, 80%) !default;

$indigo-100: tint-color($indigo, 80%) !default;
$indigo-200: tint-color($indigo, 60%) !default;
$indigo-300: tint-color($indigo, 40%) !default;
$indigo-400: tint-color($indigo, 20%) !default;
$indigo-500: $indigo !default;
$indigo-600: shade-color($indigo, 20%) !default;
$indigo-700: shade-color($indigo, 40%) !default;
$indigo-800: shade-color($indigo, 60%) !default;
$indigo-900: shade-color($indigo, 80%) !default;

$purple-100: tint-color($purple, 80%) !default;
$purple-200: tint-color($purple, 60%) !default;
$purple-300: tint-color($purple, 40%) !default;
$purple-400: tint-color($purple, 20%) !default;
$purple-500: $purple !default;
$purple-600: shade-color($purple, 20%) !default;
$purple-700: shade-color($purple, 40%) !default;
$purple-800: shade-color($purple, 60%) !default;
$purple-900: shade-color($purple, 80%) !default;

$pink-100: tint-color($pink, 80%) !default;
$pink-200: tint-color($pink, 60%) !default;
$pink-300: tint-color($pink, 40%) !default;
$pink-400: tint-color($pink, 20%) !default;
$pink-500: $pink !default;
$pink-600: shade-color($pink, 20%) !default;
$pink-700: shade-color($pink, 40%) !default;
$pink-800: shade-color($pink, 60%) !default;
$pink-900: shade-color($pink, 80%) !default;

$red-100: tint-color($red, 80%) !default;
$red-200: tint-color($red, 60%) !default;
$red-300: tint-color($red, 40%) !default;
$red-400: tint-color($red, 20%) !default;
$red-500: $red !default;
$red-600: shade-color($red, 20%) !default;
$red-700: shade-color($red, 40%) !default;
$red-800: shade-color($red, 60%) !default;
$red-900: shade-color($red, 80%) !default;

$orange-100: tint-color($orange, 80%) !default;
$orange-200: tint-color($orange, 60%) !default;
$orange-300: tint-color($orange, 40%) !default;
$orange-400: tint-color($orange, 20%) !default;
$orange-500: $orange !default;
$orange-600: shade-color($orange, 20%) !default;
$orange-700: shade-color($orange, 40%) !default;
$orange-800: shade-color($orange, 60%) !default;
$orange-900: shade-color($orange, 80%) !default;

$yellow-100: tint-color($yellow, 80%) !default;
$yellow-200: tint-color($yellow, 60%) !default;
$yellow-300: tint-color($yellow, 40%) !default;
$yellow-400: tint-color($yellow, 20%) !default;
$yellow-500: $yellow !default;
$yellow-600: shade-color($yellow, 20%) !default;
$yellow-700: shade-color($yellow, 40%) !default;
$yellow-800: shade-color($yellow, 60%) !default;
$yellow-900: shade-color($yellow, 80%) !default;

$green-100: tint-color($green, 80%) !default;
$green-200: tint-color($green, 60%) !default;
$green-300: tint-color($green, 40%) !default;
$green-400: tint-color($green, 20%) !default;
$green-500: $green !default;
$green-600: shade-color($green, 20%) !default;
$green-700: shade-color($green, 40%) !default;
$green-800: shade-color($green, 60%) !default;
$green-900: shade-color($green, 80%) !default;

$teal-100: tint-color($teal, 80%) !default;
$teal-200: tint-color($teal, 60%) !default;
$teal-300: tint-color($teal, 40%) !default;
$teal-400: tint-color($teal, 20%) !default;
$teal-500: $teal !default;
$teal-600: shade-color($teal, 20%) !default;
$teal-700: shade-color($teal, 40%) !default;
$teal-800: shade-color($teal, 60%) !default;
$teal-900: shade-color($teal, 80%) !default;

$cyan-100: tint-color($cyan, 80%) !default;
$cyan-200: tint-color($cyan, 60%) !default;
$cyan-300: tint-color($cyan, 40%) !default;
$cyan-400: tint-color($cyan, 20%) !default;
$cyan-500: $cyan !default;
$cyan-600: shade-color($cyan, 20%) !default;
$cyan-700: shade-color($cyan, 40%) !default;
$cyan-800: shade-color($cyan, 60%) !default;
$cyan-900: shade-color($cyan, 80%) !default;

$blues: (
  "blue-100": $blue-100,
  "blue-200": $blue-200,
  "blue-300": $blue-300,
  "blue-400": $blue-400,
  "blue-500": $blue-500,
  "blue-600": $blue-600,
  "blue-700": $blue-700,
  "blue-800": $blue-800,
  "blue-900": $blue-900
) !default;

$indigos: (
  "indigo-100": $indigo-100,
  "indigo-200": $indigo-200,
  "indigo-300": $indigo-300,
  "indigo-400": $indigo-400,
  "indigo-500": $indigo-500,
  "indigo-600": $indigo-600,
  "indigo-700": $indigo-700,
  "indigo-800": $indigo-800,
  "indigo-900": $indigo-900
) !default;

$purples: (
  "purple-100": $purple-100,
  "purple-200": $purple-200,
  "purple-300": $purple-300,
  "purple-400": $purple-400,
  "purple-500": $purple-500,
  "purple-600": $purple-600,
  "purple-700": $purple-700,
  "purple-800": $purple-800,
  "purple-900": $purple-900
) !default;

$pinks: (
  "pink-100": $pink-100,
  "pink-200": $pink-200,
  "pink-300": $pink-300,
  "pink-400": $pink-400,
  "pink-500": $pink-500,
  "pink-600": $pink-600,
  "pink-700": $pink-700,
  "pink-800": $pink-800,
  "pink-900": $pink-900
) !default;

$reds: (
  "red-100": $red-100,
  "red-200": $red-200,
  "red-300": $red-300,
  "red-400": $red-400,
  "red-500": $red-500,
  "red-600": $red-600,
  "red-700": $red-700,
  "red-800": $red-800,
  "red-900": $red-900
) !default;

$oranges: (
  "orange-100": $orange-100,
  "orange-200": $orange-200,
  "orange-300": $orange-300,
  "orange-400": $orange-400,
  "orange-500": $orange-500,
  "orange-600": $orange-600,
  "orange-700": $orange-700,
  "orange-800": $orange-800,
  "orange-900": $orange-900
) !default;

$yellows: (
  "yellow-100": $yellow-100,
  "yellow-200": $yellow-200,
  "yellow-300": $yellow-300,
  "yellow-400": $yellow-400,
  "yellow-500": $yellow-500,
  "yellow-600": $yellow-600,
  "yellow-700": $yellow-700,
  "yellow-800": $yellow-800,
  "yellow-900": $yellow-900
) !default;

$greens: (
  "green-100": $green-100,
  "green-200": $green-200,
  "green-300": $green-300,
  "green-400": $green-400,
  "green-500": $green-500,
  "green-600": $green-600,
  "green-700": $green-700,
  "green-800": $green-800,
  "green-900": $green-900
) !default;

$teals: (
  "teal-100": $teal-100,
  "teal-200": $teal-200,
  "teal-300": $teal-300,
  "teal-400": $teal-400,
  "teal-500": $teal-500,
  "teal-600": $teal-600,
  "teal-700": $teal-700,
  "teal-800": $teal-800,
  "teal-900": $teal-900
) !default;

$cyans: (
  "cyan-100": $cyan-100,
  "cyan-200": $cyan-200,
  "cyan-300": $cyan-300,
  "cyan-400": $cyan-400,
  "cyan-500": $cyan-500,
  "cyan-600": $cyan-600,
  "cyan-700": $cyan-700,
  "cyan-800": $cyan-800,
  "cyan-900": $cyan-900
) !default;
// fusv-enable

/* = THEME
..................................*/

$primary:       #151C37 !default;
$primary-1-5:   #D0D1D7 !default;
$primary-1-6:   #E7E7EA !default;
$primary-4-1:   #A9CFD8 !default;
$primary-4-3:   #BAD8DF !default;
$primary-4-5:   #EEF6F7 !default;
$primary-4-6:   #F6FAFB !default;
$primary-4-7:   #DCECEF !default;
$primary-2-1:   #CD8A2A !default;
$primary-2-3:   #E1B97F !default;
$primary-2-4:   #EBD0A9 !default;
$primary-2-6:   #faf3e9 !default;
$primary-3-1:   #60935E !default;
$primary-3-6:   #EFF4EE !default;
$secondary:     #55814A !default;
$secondary-1-1: #EC6523 !default;
$secondary-1-2: #D7A154 !default;
$secondary-1-4: #FDEFE8 !default;
$secondary-2-1: #6C3220 !default;
$secondary-2-3: #E1D6D2 !default;
$secondary-3-1: #EAE4E4 !default;
$neutral-1-2:   #727687 !default;
$neutral-1-3:   #E4E5E6 !default;
$accent-100:    #698D5D;
$accent-200: #EFF4EE;
$td-border-color: #AAACB7 !default;

$success:       $green !default;
$info:          $cyan !default;
$warning:       #CD8A2A !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
$accent:        #DFE9DF !default;
$accent-light:  #FAF3E9 !default;
$lighter: #F2EFEF;
$light-blue: #cbe2e7 !default;


// scss-docs-end theme-color-variables
$primary-dark: shade($primary, 10%) !default;
$primary-darker: shade($primary, 20%) !default;
$primary-darkest: shade($primary, 30%) !default;
$primary-light: #2B324A !default;
$primary-lighter: tint($primary, 20%) !default;
$primary-lightest: tint($primary, 30%) !default;

$secondary-dark: shade($secondary, 10%) !default;
$secondary-darker: shade($secondary, 20%) !default;
$secondary-darkest: shade($secondary, 30%) !default;
$secondary-light: #7FA87E !default;
$secondary-lighter: tint($secondary, 20%) !default;
$secondary-lightest: tint($secondary, 30%) !default;

$background-hr: #727687 !default;

/* = COLOR DEFINITION - BASE PALETTE
Setup your colors in a map
----------------------------------------------- */

$color-vars-common: (

	/* = COMMON COLORS
	..................................*/

	white:    $white,
	gray-100: $gray-100,
	gray-200: $gray-200,
	gray-300: $gray-300,
	gray-400: $gray-400,
	gray-500: $gray-500,
	gray-600: $gray-600,
	gray-700: $gray-700,
	gray-800: $gray-800,
	gray-900: $gray-900,

	black:    $black,
	blue:     $blue,
	indigo:   $indigo,
	purple:   $purple,
	pink:     $pink,
	red:      $red,
	orange:   $orange,
	yellow:   $yellow,
	green:    $green,
	teal:     $teal,
	cyan:     $cyan,
);

$color-vars-theme: (

	/* = THEME COLORS
	..................................*/

	primary:        $primary,
  primary-1-5:    $primary-1-5,
  primary-1-6:    $primary-1-6,
  primary-4-1:    $primary-4-1,
  primary-4-3:    $primary-4-3,
  primary-4-5:    $primary-4-5,
  primary-4-6:    $primary-4-6,
  primary-4-7:    $primary-4-7,
  primary-2-1:    $primary-2-1,
  primary-2-3:    $primary-2-3,
  primary-2-4:    $primary-2-4,
  primary-2-6:    $primary-2-6,
  primary-3-1:    $primary-3-1,
  primary-3-6:    $primary-3-6,
  neutral-1-2:    $neutral-1-2,
  neutral-1-3:    $neutral-1-3,
	secondary:      $secondary,
  secondary-1-1:  $secondary-1-1,
  secondary-1-2:  $secondary-1-2,
  secondary-1-4:  $secondary-1-4,
  secondary-2-1:  $secondary-2-1,
  secondary-2-3:  $secondary-2-3,
  secondary-3-1:  $secondary-3-1,
  secondary-light: $secondary-light,
	accent:         $accent,
  accent-100:     $accent-100,
  accent-light:   $accent-light,
  accent-200:     $accent-200,
  lighter:        $lighter,
  primary-light:  $primary-light,
  td-border-color: $td-border-color,
);

$color-vars-state: (

	/* = STATE COLORS
	..................................*/

	positive:   mediumseagreen,
	negative:   firebrick,
	success:    mediumseagreen,
	info:       blue,
	warning:    yellow,
	danger:     firebrick,

);

$color-vars-social: (

	/* = SOCIAL NETWORK COLORS
	..................................*/

	facebook:   #3b5998,
	twitter:    #5ea9dd,
	instagram:  #683d2f,

);


/* = COMBINE BASE PALETTE
..................................*/

$color-vars-palette: map-collect(
	$color-vars-common,
	$color-vars-theme,
	$color-vars-state,
	$color-vars-social,
);


/* = CREATE COLOR FUNCTION - PALETTE

!![[ For use inside this file only ]]
----------------------------------------------- */

@function color-palette__($color) {
	@return map-get($color-vars-palette, $color);
}





/* = COLOR DEFINITION - APPLIED
Setup your colors in a map
----------------------------------------------- */

$color-vars-applied-text: (

	/* = TEXT COLORS
	..................................*/

	text-main:            color-palette__(black),
	link:                 color-palette__(primary),
	link-visited:         darken( color-palette__(primary), 25% ),
	link-hover:           darken( color-palette__(primary), 20% ),

);

$color-vars-applied: map-collect(
	$color-vars-applied-text,
);


/* = COMBINE PALETTE and APPLIED COLOR MAPS
----------------------------------------------- */

$color-vars-all: map-collect($color-vars-palette, $color-vars-applied);


/* = NEXT FILE IN CHAIN
  -> _color-register.scss
----------------------------------------------- */

// // All-caps `RGBA()` function $color-vars-palette because of this Sass bug: https://github.com/sass/node-sass/issues/2251
// @each $color, $value in $theme-colors {
//   .text-#{$color} {
//     color: $value !important;
//   }
// }
