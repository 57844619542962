/*------------------------------------*\
  # VENDOR
\*------------------------------------*/


/* = BOOTSTRAP
----------------------------------------------- */

/* = CONFIGURATION
..................................*/

/* = FUNCTIONS and MIXINS
=========*/

@import "bootstrap/scss/functions";
@import "variables-settings/bootstrap-functions-clobber";
@import "bootstrap/scss/mixins";
// @import "bootstrap-icons/font/bootstrap-icons.scss";

/* = VARIABLES and MAPS
=========*/
@import "variables-settings/variables-settings-master"; // WAMMCOs variables
@import "variables-settings/bootstrap-settings"; // Bootstrap Overrides (Colors above)

@import "bootstrap/scss/variables"; // Bootstrap Defaults

@import "variables-settings/bootstrap-maps-override"; // Boostrap map overrides
@import "bootstrap/scss/maps"; // Bootstrap maps

/* = UTILITIES
=========*/

@import "bootstrap/scss/utilities";


/* = LAYOUT and COMPONENTS
..................................*/

@import "bootstrap/scss/root"; // CSS Variables
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

/* = HELPERS
..................................*/

@import "bootstrap/scss/helpers/clearfix";
@import "bootstrap/scss/helpers/color-bg";
@import "bootstrap/scss/helpers/colored-links";
@import "bootstrap/scss/helpers/ratio";
@import "bootstrap/scss/helpers/position";
@import "bootstrap/scss/helpers/stacks";
@import "bootstrap/scss/helpers/visually-hidden";
@import "bootstrap/scss/helpers/stretched-link";
@import "bootstrap/scss/helpers/text-truncation";
@import "bootstrap/scss/helpers/vr";


// Utilities
@import "bootstrap/scss/utilities/api";

.gfield.gfield--type-submit {
  @extend .col-48, .col-lg-16
}
